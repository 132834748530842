<template>
  <div class="Slider">
    <Teleport to="#subnav">
      <transition name="drift">
        <nav v-if="mounted && payload.filters?.length" class="inner flex mt-2">
          <a
            v-for="tag of payload.filters"
            :key="tag.id"
            href="#"
            class="link"
            :class="{ active: tag.slug == filter }"
            @click.prevent="filterEntries(tag)"
            v-text="tag.title"
          />
        </nav>
      </transition>
    </Teleport>

    <div v-if="mounted" class="constrain">
      <transition name="drift" mode="out-in">
        <div :key="filter">
          <swiper
            v-if="!isMobile"
            class="main-swiper"
            :free-mode="true"
            slides-per-view="auto"
            direction="vertical"
            :breakpoints="{ 920: { direction: 'horizontal' } }"
            :mousewheel="{ eventsTarget: '.Slider' }"
            :keyboard="true"
            :preload-images="false"
            @swiper="onSwiper"
          >
            <swiper-slide class="md:hidden">
              <Logo class="logo" />
            </swiper-slide>

            <swiper-slide
              v-for="(slide, index) of slides"
              :key="slide.id"
              :class="['n-' + (index % 5), { big: index % 5 == 0 }]"
              class="slide"
            >
              <Entry
                :payload="slide.data"
                :parent="payload.parent"
                :nr="index % 5"
                @focus="focus(index)"
                @unfocus="focus()"
              />
            </swiper-slide>
          </swiper>
          <div v-else class="mobile">
            <Logo class="logo" />
            <Entry
              v-for="(slide, index) of slides"
              :key="slide.id"
              :ref="`mobileElements_${index}`"
              :payload="slide.data"
              :parent="payload.parent"
              :nr="index % 5"
              @focus="focus(index)"
              @unfocus="focus()"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SwiperCore, {
  A11y, Keyboard, Mousewheel, Navigation,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Logo from '@/assets/images/logo.svg';
import Entry from '../part/Entry.vue';

SwiperCore.use([A11y, Keyboard, Mousewheel, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    Entry,
    Logo,
  },

  props: {
    payload: { type: Object, default: Object },
  },

  data: () => ({
    mounted: false,
    swiper: null,
    filter: null,
    width: 0,
  }),

  computed: {
    slides() {
      if (this.filter) {
        return this.payload.entries
          .filter((e) => e.data.tags.find((t) => t.slug === this.filter));
      }
      return this.payload.entries;
    },
    isMobile() { return this.width < 920; },
  },

  beforeUnmount() {
    this.mounted = false;
  },

  mounted() {
    if (window.location.hash) {
      const tag = window.location.hash.substring(1);
      if (this.payload.entries.filter((e) => e.data.tags.find((t) => t.slug === tag))) {
        this.filter = tag;
      }
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.filter = this.payload.filters?.[0].slug || null;
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.mounted = true;
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    focus(target) {
      if (this.swiper) {
        this.swiper.update();
        if (target) setTimeout(() => this.swiper.slideTo(target + 1), 100);
      } else if (target) {
        setTimeout(() => {
          this.$refs[`mobileElements_${target}`].scrollTo();
        }, 100);
      }
    },
    filterEntries(tag) {
      this.filter = this.filter === tag.slug ? null : tag.slug;
      window.history.replaceState(undefined, undefined, this.filter ? `#${this.filter}` : ' ');
    },
    onResize() {
      this.width = window.innerWidth;
    },
  },

};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.main-swiper {
  display: none;
  @screen md {
    display: block;
  }
}
.mobile {
  display: block;
  @screen md {
    display: none;
  }
}
.Slider {
  margin-top: 0;
  @screen md {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 0;
    margin-top: 5%;
  }

  @media (max-height: 1000px) and (min-width: theme("screens.md")) {
    padding-top: 50px;
    margin-top: 0;
    transform-origin: center left;
    transform: scale(0.7);
  }
}

.swiper-container {
  overflow: visible;
  max-height: calc(100vh - 120px);
  padding-bottom: 80px;
  @screen md {
    padding-bottom: 0;
  }
}

.swiper-wrapper {
  will-change: transform;
}

.logo {
  width: 80%;
  margin: 15% 0;
  padding-left: 5%;
}

.slide {
  width: 100%;

  @screen md {
    width: 28%;
    &.n-0 {
      margin-left: 2%;
      width: 50%;
    }
    &:first-child {
      margin-left: 0;
    }
    &.n-1 {
      margin-top: 21%;
      margin-left: -3.5%;
    }
    &.n-2 {
      margin-top: 9.5%;
      margin-left: 1.5%;
    }
    &.n-3 {
      width: 0;
      margin-top: 65%;
      :deep(.Entry) {
        position: fixed;
        width: 17%;
        transform: translateX(-32vw) translateY(-100%);
      }
    }
    &.n-4 {
      margin-top: 21%;
      margin-left: -5.5%;
    }
  }
}

nav {
    @media (max-width: theme("screens.md")) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    top: -4rem;
  }
}

.link {
  background: var(--color-secondary-light);
  backdrop-filter: blur(12px);
  padding: 0.3em 1.3em;
  color: white;
  text-transform: uppercase;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.2));
  transition: background 0.3s ease;
  white-space: nowrap;
  letter-spacing: 1px;
  font-size: 0.6rem;
  flex-grow: 1;
  text-align: center;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.active {
    background: var(--color-secondary);
  }

  &:hover {
    background: rgba(47, 53, 75, 0.7);
  }

  @media (max-width: theme("screens.md")) {
    font-size: 0.6em;
    padding: 0.2em 2em;
  }
}
</style>
