<template>
  <a
    :href="payload.link"
    class="Entry mb-4"
    ref="content"
    @click.prevent="showDetails($event)"
  >
    <div class="outer-cut" :class="{ ' md:order-2': nr === 3 }">
      <div
        class="aspect a32 cover"
        :class="{
          'cut-big': nr === 0,
          'cut-small': nr !== 0,
          'cut-mini': nr === 3,
        }"
      >
        <Image v-if="!isMobile" :src="payload.hauptserie[0]" />
        <div v-else>
          <Close
            v-if="active"
            class="close right-4 md:right-10"
            @click.prevent.stop="hideDetails"
          />

          <div v-if="payload.map" class="aspect a32">
            <Map />
          </div>

          <swiper v-else :navigation="true" :preload-images="false">
            <swiper-slide
              v-for="slide of payload.hauptserie.concat(payload.sekundarserie)"
              :key="slide.id"
              class="slide"
            >
              <Image :src="slide" class="inner-img" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

    <!-- Card -->
    <div class="card caption" :class="{ active }">
      <div class="ml-md-6 pl-2 pt-4">
        <h2 class="md:pl-2" v-html="payload.caption" />
      </div>
      <div class="px-4 mt-4 more">
        <div v-html="payload.text" />
      </div>
    </div>
  </a>

  <!-- Desktop has Popup -->
  <Teleport v-if="ready && !isMobile" to="#overlay">
    <transition name="appear">
      <Detail v-if="active" :payload="payload" @close="hideDetails" />
    </transition>
  </Teleport>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Close from '@/assets/images/close.svg';

SwiperCore.use([A11y, Pagination, Navigation]);

const Detail = defineAsyncComponent(() => import('./Detail.vue'));
const Map = defineAsyncComponent(() => import('@/components/part/Map.vue'));

export default {

  components: {
    Swiper,
    SwiperSlide,
    Detail,
    Close,
    Map,
  },

  props: {
    payload: { type: Object, default: Object },
    parent: { type: String, required: true },
    nr: { type: Number, required: true },
  },

  emits: ['focus', 'unfocus'],

  data: () => ({
    ready: false,
    active: false,
    width: 0,
  }),

  computed: {
    popup() { return this.$store.state.router.popup; },
    isMobile() { return this.width < 920; },
  },

  watch: {
    popup(state, prev) {
      // new state empty => close
      if (state === null || state.meta.uri !== this.payload.link) {
        // if (this.active && this.isMobile) setTimeout(() => this.$emit('unfocus'), 300);
        this.active = false;
        this.$nextTick(() => { this.ready = false; });
        return;
      }

      // this page will open soon
      if (state && !this.active && state.meta.uri === this.payload.link) {
        if (this.isMobile) setTimeout(() => this.$emit('focus'), 300);
        this.ready = true;
        this.$nextTick(() => { this.active = true; });
      }
    },
  },

  mounted() {
    if (window.getPath() === this.payload.link) {
      if (this.$store.state.router.popup) {
        if (this.isMobile) setTimeout(() => this.$emit('focus'), 300);
        this.ready = true;
        this.active = true;
      } else {
        this.$store.dispatch('router/popup', { uri: this.payload.link });
      }
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    showDetails() {
      this.$store.dispatch('router/popup', { uri: this.payload.link });
    },
    hideDetails() {
      this.$store.dispatch('router/push', { uri: this.parent, noscroll: true });
    },
    onResize() {
      this.width = window.innerWidth;
    },
    scrollTo() {
      this.$refs.content.scrollIntoView();
    },
  },

};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

/*
  SMALL
*/
.Entry {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: var(--color-secondary);

  @screen md {
    &:hover {
      h2 {
        border-left-color: var(--color-primary);
      }
      .outer-cut {
        filter: drop-shadow(20px 5px 40px rgba(0, 0, 0, 0.4));
      }
    }
  }
}

.outer-cut {
  position: relative;
  z-index: 1;
  will-change: filter;
  filter: drop-shadow(20px 5px 40px rgba(0, 0, 0, 0.2));
  transition: .25s ease;
}

.caption {
  padding-top: 15px;
  margin-top: -15px;

  &::before {
    display: none;
    @screen md {
      content: "";
      background: rgba(255, 255, 255, 0);
      width: 100%;
      padding-bottom: 63%;
      display: block;
      position: absolute;
      transform: translateY(-100%);
      clip-path: polygon(0 100%, 0 93%, 100% 0, 100% 100%);
    }
  }

  .more {
    max-height: 0;
    transition: 0.4s ease;
    overflow: hidden;
  }

  @media (max-width: theme("screens.md")) {
    &.active {
      background: white;
      filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.2));
      will-change: filter;
      padding-bottom: 1px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      &::before {
        background: white;
      }

      .more {
        max-height: 800px;
      }
    }
  }

  h2 {
    white-space: nowrap;
    border-left: 8px solid;
    border-color: transparent;
    color: var(--color-secondary);
    transition: all 0.2s ease;
    hyphens: none;
  }

  @screen md {
    .big & h2 {
      @apply text-xl;
    }
    .n-3 & h2 {
      margin-left: 25%;
    }
  }
}

.swiper-container {
  height: 100%;
  .inner-img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.close {
  position: absolute;
  top: 25px;
  width: 25px;
  z-index: 10;
  color: white;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.2));
}

:deep(img) {
  z-index: 100;
}
</style>
